<script setup>
import { inject } from 'vue';

// Components
import Modal from '@/Components/Popup/Modal.vue';

// Partials
import ApproveHoursForm from './UpdateHoursForm.vue';

const dayjs = inject('dayjs');
const route = inject('route');
const props = defineProps({
    showing: Boolean,
    shift: Object,
    hideNextButton: Boolean,
});

const emits = defineEmits(['update:showing', 'nextRowClicked']);
const closeForm = () => emits('update:showing', false);

const sendNextRow = (payload) => {
    emits('nextRowClicked', payload);
};
</script>

<template>
    <Modal :open="showing" @closing="closeForm" class="sm:max-w-[800px]">
        <template #default>
            <h2 class="text-blue text-[32px] font-bold">
                <span v-if="shift.start">{{ dayjs(shift.start).format('DD-MM-YYYY') + '  ' }}</span>
                <span v-if="shift.start">{{ dayjs(shift.start).format('H:mm') + ' - ' }}</span>
                <span v-if="shift.end">{{ dayjs(shift.end).format('H:mm') + ' · ' }}</span>
                <span v-if="shift.position">
                    {{ shift.position.name }}
                </span>
            </h2>

            <ApproveHoursForm
                :shift="shift"
                :showing="showing"
                @next-row-clicked="sendNextRow"
                :hideNextButton="hideNextButton"
            />
        </template>
    </Modal>
</template>
